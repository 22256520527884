import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchFactsData = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          number {
            description1
            description2
            description3
            description4
            header
            number1
            number2
            number3
            number4
            subtitle
            copy
            title
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.number;
  } catch (error) {
    console.error('Error fetching facts data:', error);
    return null;
  }
};

export { fetchFactsData };
