import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchEmailData = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          email {
            email
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.email;
  } catch (error) {
    console.error('Error fetching email data:', error);
    return null;
  }
};

export { fetchEmailData };
