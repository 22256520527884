// Loader.js
import React, { useEffect, useState } from 'react';
import './Loader.css';
import NC_INTRO from './assets/NC_INTRO.mp4'; 

const Loader = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    useEffect(() => {
        if (isVideoLoaded) {
            const timer = setTimeout(() => {
                setIsLoaded(true);
            }, 3300);

            return () => clearTimeout(timer);
        }
    }, [isVideoLoaded]);

    return (
        <div className={`loader ${isLoaded ? 'come-out' : 'come-in'}`}>
            <video
                src={NC_INTRO}
                style={{ width: '400px', objectFit: 'cover' }}
                autoPlay={true}
                onLoadedData={() => setIsVideoLoaded(true)}
                onEnded={() => setIsLoaded(true)}
                playsInline
                muted
            />
        </div>
    );
};

export default Loader;
