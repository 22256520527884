import React from 'react';
import { Slider, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from './colors';

const ValueLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
};

const CustomSlider = styled(Slider)({
    color: colors.green,
    '& .MuiSlider-thumb': {
        backgroundColor: colors.white,
        '&:hover, &.Mui-focusVisible': {
            boxShadow: '0px 0px 0px 8px rgba(112, 221, 3, 0.16)',
        },
        '&.Mui-active': {
            boxShadow: '0px 0px 0px 14px rgba(112, 221, 3, 0.16)',
        },
        '& .MuiSlider-valueLabel': {
            backgroundColor: colors.green,
            color: colors.white,
        },
    },
    '& .MuiSlider-track': {
        backgroundColor: colors.green,
    },
    '& .MuiSlider-rail': {
        backgroundColor: colors.greyBackground,
    },
    width: 'calc(100% - 30px)',
    marginRight: '10px',
    marginLeft: '10px'
});

const stylesSlider = {
    sliderValueLabel: {
        backgroundColor: colors.greyBackground,
        color: colors.greyText
    },
};

export { CustomSlider, ValueLabelComponent, stylesSlider };
