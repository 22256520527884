import React, { useState, useEffect } from 'react';
import './Global.css';
import Fact from './fact';
import Introducer from './introducer';
import { fetchFactsData } from './cms/cmsFacts';


const Facts = ({ phone }) => {
    const [factsData, setFactsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchFactsData();
            setFactsData(data);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading || !factsData) {
        return <div style={{ height: '200px' }}>Loading...</div>;
    }

    return (
        <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '80px', paddingLeft: phone ? '15px' : null, paddingRight: phone ? '15px' : null}}>
            <Introducer phone={phone} sectionTitle={factsData.header} title={factsData.title} body={<>{factsData.subtitle}<br /><br />{factsData.copy}</>} />
        </div>
    );
};

export default Facts;
