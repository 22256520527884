import React, { useState, useEffect } from 'react';
import { fetchContextData } from './cms/cmsContext';
import './Global.css';

const ContextPhrase = ({ phone }) => {
  const [paragraph, setParagraph] = useState('');

  useEffect(() => {
    const getContextData = async () => {
      const data = await fetchContextData();
      if (data) {
        setParagraph(data.paragraph);
      }
    };
    getContextData();
  }, []);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '100px', paddingBottom: '20px' }}>
      <h4 style={{ fontSize: phone ? '22px' : '32px', width: phone ? '75%' : '60%', textAlign: 'center', lineHeight: '1.5' }}>
        {paragraph}
      </h4>
    </div>
  );
}

export default ContextPhrase;
