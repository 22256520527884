import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchGalleryItems = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          gallery {
            items {
              body
              tag
              title
              id
              image {
                url
              }
            }
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.gallery.items;
  } catch (error) {
    console.error('Error fetching gallery items:', error);
    return [];
  }
};

export { fetchGalleryItems };
