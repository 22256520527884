// cmsFounders.js
import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchFoundersData = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          team {
            body
            description1
            description2
            foundername1
            foundername2
            function1
            function2
            header
            title
            founderpicture1 {
              url
            }
            founderpicture2 {
              url
            }
            link1
            link2
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.team;
  } catch (error) {
    console.error('Error fetching founders data:', error);
    return null;
  }
};

export { fetchFoundersData };
