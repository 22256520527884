import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './NavBar.css';
import './Global.css';
import logo from './assets/logo.png';
import colors from './colors';
import { fetchEmailData } from './cms/cmsEmail';
import { useModal } from './modalContext'; // Import useModal context

function NavBarPhone({ hideMiddleSection }) {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [email, setEmail] = useState('');

  const { setShowModal, setVariant } = useModal(); // Use useModal context

  useEffect(() => {
    const getEmail = async () => {
      const data = await fetchEmailData();
      setEmail(data ? data.email : '');
    };
    getEmail();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setIsScrollingDown(true);
      } else {
        setIsScrollingDown(false);
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const handleContactClick = () => {
    setShowModal(true);
    setVariant('short'); 
  };

  const scrollToSection = (event, id) => {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="nav-bar" style={{ top: '25px', left: '25px', right: '25px' }}>
      <a href="/"> <img src={logo} alt="Logo" className={`nav-logo ${isScrollingDown ? 'fade-out' : 'fade-in'}`} /></a>

      <div style={{ width: '120px', display: 'flex', justifyContent: 'flex-end' }}>
        <a
          onClick={handleContactClick}
          className={`nav-link contact-button ${isScrollingDown ? 'fade-out' : 'fade-in'}`}
          style={{ backgroundColor: '#F5F5F5', color: colors.black, cursor: 'pointer' }}
        >
          Contact
        </a>
      </div>
    </div>
  );
}

NavBarPhone.propTypes = {
  hideMiddleSection: PropTypes.bool,
};

export default NavBarPhone;
