// cmsColdPages.js
import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchColdPages = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          coldpage {
            cookies
            privacy
            termsofuse
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.coldpage;
  } catch (error) {
    console.error('Error fetching cold pages:', error);
    return {};
  }
};

export { fetchColdPages };
