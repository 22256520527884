import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [variant, setVariant] = useState(null);

  const resetModalStates = () => {
    setShowModal(false);
    setVariant(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, setShowModal, variant, setVariant, resetModalStates }}>
      {children}
    </ModalContext.Provider>
  );
};
