import React, { useState, useRef, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, Select, MenuItem } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import './Form.css';
import colors from './colors';
import linkedIn from './assets/linkedIn.png';
import { CustomSlider, ValueLabelComponent } from './customSlider';


const preventDefault = (e) => {
  e.preventDefault();
};

function Form({ onClose, phone, short }) {
  const [name, setName] = useState('');
  const [ncmp] = useState('MPNC91!MPNC91!');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [business, setBusiness] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [scope, setScope] = useState('');
  const [technicalSupport, setTechnicalSupport] = useState('');
  const [sampleQuantity, setSampleQuantity] = useState(100);
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [hearAboutNovaCarbon, setHearAboutNovaCarbon] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [hearAboutOther, setHearAboutOther] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [country, setCountry] = useState('');

  const modalRef = useRef(null);
  const scrollableContentRef = useRef(null);
  const scrollableContentRef2 = useRef(null);
  const handleCaptchaChange = (value) => {
    if (value) {
      setTimeout(() => {
        setShowCaptcha(false);
        setReadyToSubmit(true);
      }, 1000);
    }
  };

  useEffect(() => {
    if (readyToSubmit) {
      const formData = short
        ? {
          name,
          ncmp,
          surname,
          business,
          email,
          phone: phoneInput,
          message,
        }
        : {
          name,
          ncmp,
          surname,
          email,
          business,
          phone: phoneInput,
          sampleQuantity: sampleQuantity.toString(),
          reason,
          technicalSupport,
          scope,
          message,
          recipientName,
          businessName,
          streetName,
          city,
          state,
          zipcode,
          country,
          hearAboutNovaCarbon: hearAboutNovaCarbon === 'Other' ? hearAboutOther : hearAboutNovaCarbon,
        };

      const submitForm = async () => {
        try {
          const response = await fetch('https://form.mesprojets.app/nc/form.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify(formData),
          });

          if (response.status === 422) {
            throw new Error('Validation error');
          } else if (!response.ok) {
            throw new Error('Something went wrong');
          }

          const data = await response.json();
          setFormSubmitted(true);
        } catch (error) {
          console.error('Error submitting form data:', error);
        }
      };

      submitForm();
    }
  }, [
    readyToSubmit,
    name,
    ncmp,
    surname,
    business,
    email,
    phoneInput,
    sampleQuantity,
    reason,
    technicalSupport,
    scope,
    hearAboutNovaCarbon,
    hearAboutOther,
    message,
    short,
    recipientName,
    businessName,
    streetName,
    city,
    state,
    zipcode,
    country,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowCaptcha(true);
  };

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isStep1Valid = name !== '' && surname !== '' && email !== '' && recipientName !== '' && streetName !== '' && city !== '' && zipcode !== '' && country !== '' && emailPattern.test(email);
  const isShortValid = name !== '' && surname !== '' && email !== ''/*  && emailPattern.test(email) */;
  const isStep2Valid = sampleQuantity > 0;

  const renderShortForm = () => (
    <form style={styles.formContainer} onSubmit={handleSubmit}>
      <h3
        style={{
          ...styles.textAlignCenter,
          fontSize: phone ? '30px' : '48px',
          lineHeight: phone ? '40px' : '56px',
          paddingTop: phone ? '' : '36px',
        }}
      >
        Get in touch with <br />Nova Carbon®
      </h3>
      <div style={styles.formContent} className="form-content">
        <div
          style={{
            pointerEvents: 'none',
            height: '20px',
            width: '100%',
            background: 'linear-gradient(to bottom, white, transparent)',
            zIndex: 100,
            position: 'absolute',
            top: -1,
            left: 0,
            right: 0,
          }}
        />
        <div
          style={{
            pointerEvents: 'none',
            height: '20px',
            width: '100%',
            background: 'linear-gradient(to top, white, transparent)',
            zIndex: 100,
            position: 'absolute',
            bottom: -1,
            left: 0,
            right: 0,
          }}
        />
        <div className="form-content-inner scrollable-content" ref={scrollableContentRef}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <label
              style={{
                ...styles.label
              }}
              className="small-text"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                First name
                <span
                  className="small-text"
                  style={{
                    color: colors.greyText,
                    paddingLeft: '4px',
                    opacity: 0.8
                  }}
                >
                  (Required)
                </span>
              </div>
              <input
                type="text"
                placeholder="First Name..."
                style={styles.input}
                name="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>

            <label
              style={{
                ...styles.label,
                paddingLeft: '5px'
              }}
              className="small-text"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                Last name
                <span
                  className="small-text"
                  style={{
                    color: colors.greyText,
                    paddingLeft: '4px',
                    opacity: 0.8
                  }}
                >
                  (Required)
                </span>
              </div>
              <input
                type="text"
                placeholder="Last Name..."
                style={styles.input}
                name="surname"
                required
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <label
              style={{
                ...styles.label
              }}
              className="small-text"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                Your email
                <span
                  className="small-text"
                  style={{
                    color: colors.greyText,
                    paddingLeft: '4px',
                    opacity: 0.8
                  }}
                >
                  (Required)
                </span>
              </div>
              <input
                type="text"
                placeholder="example@provider.com"
                style={styles.input}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
               /*  pattern={emailPattern} */
                required
              />
            </label>

            <label
              style={{
                ...styles.label,
                paddingLeft: '5px'
              }}
              className="small-text"
            >
              Your telephone
              <input
                type="text"
                placeholder="Phone number..."
                style={styles.input}
                value={phoneInput}
                onChange={(e) => setPhoneInput(e.target.value)}
                name="telephone"
              />
            </label>
          </div>
          <label
            style={{
              ...styles.label
            }}
            className="small-text"
          >
            Your company
            <input
              type="text"
              placeholder={phone ? 'Business Name...' : 'The company you are representing'}
              style={styles.input}
              name="business"
              value={business}
              onChange={(e) => setBusiness(e.target.value)}
            />
          </label>
          <label
            style={{
              ...styles.label
            }}
            className="small-text"
          >
            Send us a message
            <textarea
              style={{ ...styles.input, ...styles.commentBox }}
              type="text"
              name="message"
              placeholder="Write in here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="input comment-box"
            />
          </label>
        </div>
      </div>
      <div style={styles.buttonsContainer}>
        <button
          style={styles.button(colors.green, colors.black, !isShortValid)}
          className="rounded-button small-text"
          type="submit"
          disabled={!isShortValid}
        >
          Submit
        </button>
      </div>
    </form>
  );

  const renderLongForm = () => (
    <form style={styles.formContainer} onSubmit={handleSubmit}>
      <h3
        style={{
          ...styles.textAlignCenter,
          fontSize: phone ? '30px' : '48px',
          lineHeight: phone ? '40px' : '56px',
          paddingTop: phone ? '' : '36px'
        }}
      >
        Get a sample of <br />
        Nova Carbon®
      </h3>
      <div style={styles.formContent} className="form-content">
        <div
          style={{
            pointerEvents: 'none',
            height: '20px',
            width: '100%',
            background: 'linear-gradient(to bottom, white, transparent)',
            zIndex: 100,
            position: 'absolute',
            top: -1,
            left: 0,
            right: 0,
          }}
        />
        <div
          style={{
            pointerEvents: 'none',
            height: '100px',
            width: '100%',
            background: 'linear-gradient(to top, white, transparent)',
            zIndex: 100,
            position: 'absolute',
            bottom: -1,
            left: 0,
            right: 0,
          }}
        />
        {currentStep === 1 && (
          <div className="form-content-inner scrollable-content" ref={scrollableContentRef}>
            <p className="small-text" style={{ color: colors.greyText, paddingTop: '12px', opacity: 0.5, alignSelf: 'center' }}>
              (〜 1 min)
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <label
                style={{
                  ...styles.label
                }}
                className="small-text"
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  First name
                  <span
                    className="small-text"
                    style={{
                      color: colors.greyText,
                      paddingLeft: '4px',
                      opacity: 0.8
                    }}
                  >
                    (Required)
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="First Name..."
                  style={styles.input}
                  name="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>

              <label
                style={{
                  ...styles.label,
                  paddingLeft: '5px'
                }}
                className="small-text"
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  Last name
                  <span
                    className="small-text"
                    style={{
                      color: colors.greyText,
                      paddingLeft: '4px',
                      opacity: 0.8
                    }}
                  >
                    (Required)
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Last Name..."
                  style={styles.input}
                  name="surname"
                  required
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
              </label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <label
                style={{
                  ...styles.label
                }}
                className="small-text"
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  Your email
                  <span
                    className="small-text"
                    style={{
                      color: colors.greyText,
                      paddingLeft: '4px',
                      opacity: 0.8
                    }}
                  >
                    (Required)
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="example@provider.com"
                  style={styles.input}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  /* pattern={emailPattern} */
                  required
                />
              </label>

              <label
                style={{
                  ...styles.label,
                  paddingLeft: '5px'
                }}
                className="small-text"
              >
                Your telephone
                <input
                  type="text"
                  placeholder="Phone number..."
                  style={styles.input}
                  value={phoneInput}
                  onChange={(e) => setPhoneInput(e.target.value)}
                  name="telephone"
                />
              </label>
            </div>
            <label
              style={{
                ...styles.label
              }}
              className="small-text"
            >
              Your company
              <input
                type="text"
                placeholder={phone ? 'Business Name...' : 'The company you are representing'}
                style={styles.input}
                name="business"
                value={business}
                onChange={(e) => setBusiness(e.target.value)}
              />
            </label>
            <label
              style={{
                ...styles.label,
                paddingTop: '36px'
              }}
              className="small-text"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                Delivery address
                <span
                  className="small-text"
                  style={{
                    color: colors.greyText,
                    paddingLeft: '4px',
                    opacity: 0.8
                  }}
                >
                  (Required)
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <input
                  type="text"
                  placeholder="Recipient's full name"
                  style={styles.input}
                  name="recipientName"
                  value={recipientName}
                  onChange={(e) => setRecipientName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Business Name"
                  style={{ ...styles.input, marginLeft: '5px' }}
                  name="businessName"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  required
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <input
                  type="text"
                  placeholder="Street Name"
                  style={styles.input}
                  name="streetName"
                  value={streetName}
                  onChange={(e) => setStreetName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="City"
                  style={{ ...styles.input, marginLeft: '5px' }}
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <input
                  type="text"
                  placeholder="State/Region"
                  style={styles.input}
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Zipcode"
                  style={{ ...styles.input, marginLeft: '5px' }}
                  name="zipcode"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Country"
                  style={{ ...styles.input, marginLeft: '5px' }}
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </div>
            </label>

            <label
              style={{
                ...styles.label,
                paddingTop: '44px'
              }}
              className="small-text"
            >
              How did you hear about Nova Carbon®?
              <Select
                value={hearAboutNovaCarbon}
                onChange={(e) => setHearAboutNovaCarbon(e.target.value)}
                displayEmpty
                style={{ ...styles.input }}
                sx={{
                  height: '45px',
                  backgroundColor: colors.white,
                  border: colors.greyText,
                  borderWidth: 0,
                  '.MuiSelect-select': {
                    color: colors.black,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  boxShadow: '0 1px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <MenuItem sx={styles.menuItem} value="" disabled>
                  Select an option
                </MenuItem>
                <MenuItem sx={styles.menuItem} className="menuItemStyles" value="Press">Press</MenuItem>
                <MenuItem sx={styles.menuItem} value="Social Media">Social Media</MenuItem>
                <MenuItem sx={styles.menuItem} value="Word of Mouth">Word of Mouth</MenuItem>
                <MenuItem sx={styles.menuItem} value="Events">Events</MenuItem>
                <MenuItem sx={styles.menuItem} value="Other">Other</MenuItem>
              </Select>
              {hearAboutNovaCarbon === 'Other' && (
                <label
                  style={styles.label}
                  className="small-text"
                >
                  Please specify
                  <input
                    type="text"
                    value={hearAboutOther}
                    onChange={(e) => setHearAboutOther(e.target.value)}
                    placeholder="Write in here..."
                    style={styles.input}
                  />
                </label>
              )}
            </label>
          </div>
        )}

        {currentStep === 2 && (
          <div className="form-content-inner scrollable-content" ref={scrollableContentRef2}>
            <label
              style={{
                ...styles.label,
                paddingTop: '34px'
              }}
              className="small-text"
            >
              Sample quantity ({sampleQuantity} g)
              <CustomSlider
                value={sampleQuantity}
                onChange={(e, value) => setSampleQuantity(value)}
                aria-labelledby="sample-quantity-slider"
                valueLabelDisplay="auto"
                step={100}
                marks
                min={100}
                max={1000}
                ValueLabelComponent={ValueLabelComponent}
                componentsProps={{
                  valueLabel: {
                    className: styles.sliderValueLabel,
                  },
                }}
              />
            </label>
            <label
              style={{
                ...styles.label
              }}
              className="small-text"
            >
              Why do you want to use Nova Carbon®?
              <textarea
                style={{ ...styles.input, ...styles.commentBox }}
                type="text"
                aria-multiline
                name="reason"
                placeholder="Please provide us with details on your project, your objectives, and the reasons you are interested in using Nova Carbon®"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="input comment-box"
              />
            </label>
            <label
              style={{
                ...styles.label,
                paddingTop: '54px'
              }}
              className="small-text"
            >
              Do you need technical support in applying the material?
              <div style={{ display: 'flex', paddingTop: '12px', justifyContent: phone ? 'center' : null, width: phone ? '100%' : null }}>
                <FormControl style={{ width: '100%' }}>
                  <RadioGroup
                    style={{ width: '100%' }}
                    row
                    aria-label="technicalSupport"
                    name="technicalSupport"
                    value={technicalSupport}
                    onChange={(e) => setTechnicalSupport(e.target.value)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        paddingRight: '14px',
                        borderRadius: '14px',
                        marginBottom: '36px',
                        boxSizing: 'border-box',
                        boxShadow: '0 1px 6px rgba(0, 0, 0, 0.1)',
                        justifyContent: 'center',
                        paddingLeft: phone ? '20%' : '24px'
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        style={{ width: phone ? '40%' : null }}
                        control={
                          <Radio
                            sx={{
                              '&, &.Mui-checked': {
                                color: colors.green,
                              },
                            }}
                          />
                        }
                        label={
                          <span className="small-text" style={{ fontSize: '13px', marginLeft: '-3px', paddingRight: '24px' }}>
                            Yes
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="no"
                        style={{ width: phone ? '40%' : null }}
                        control={
                          <Radio
                            sx={{
                              '&, &.Mui-checked': {
                                color: colors.green,
                              },
                            }}
                          />
                        }
                        label={
                          <span className="small-text" style={{ fontSize: '13px', marginLeft: '-3px' }}>
                            No
                          </span>
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </label>
            <label
              style={{
                ...styles.label,
                paddingTop: '20px'
              }}
              className="small-text"
            >
              What is the scope of your project?
              <textarea
                style={{ ...styles.input, ...styles.commentBox }}
                type="text"
                aria-multiline
                name="scope"
                placeholder="E.g. A Proof of Concept, a small series (20 to 100 pieces), an industrial project..."
                value={scope}
                onChange={(e) => setScope(e.target.value)}
                className="input scope-box"
              />
            </label>
          </div>
        )}
      </div>
      <div style={styles.buttonsContainer}>
        {currentStep > 1 && (
          <button
            style={styles.button(colors.greyBackground, colors.black)}
            className="rounded-button small-text"
            type="button"
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </button>
        )}
        {currentStep < 2 && (
          <button
            style={styles.button(colors.green, colors.black, currentStep === 1 && !isStep1Valid)}
            className="rounded-button small-text"
            type="button"
            disabled={currentStep === 1 && !isStep1Valid}
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Next
          </button>
        )}
        {currentStep === 2 && (
          <button
            style={styles.button(colors.green, colors.black, currentStep === 2 && !isStep2Valid)}
            className="rounded-button small-text"
            type="submit"
            disabled={currentStep === 2 && !isStep2Valid}
          >
            Submit
          </button>
        )}
      </div>
    </form>
  );

  return (
    <div className="modal-overlay">
      <div style={phone ? styles.modalFullScreen : styles.modalHalfScreen} className="modal" ref={modalRef}>
        <button style={styles.closeButton} onClick={onClose}>
          <p style={{ margin: 0, padding: 0, fontSize: '24px' }} className="main-text">
            ×
          </p>
        </button>

        {!formSubmitted ? (
          short ? renderShortForm() : renderLongForm()
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
            }}
            className="form-submitted"
          >
            <h3
              style={{
                ...styles.textAlignCenter,
                fontSize: phone ? '30px' : '48px',
                lineHeight: phone ? '40px' : '56px',
                paddingTop: phone ? '' : '36px',
                paddingBottom: '40px',
                width: '60%',
              }}
            >
              Form submitted, thank you.
            </h3>
            <p className="main-text" style={{ textAlign: 'center', paddingBottom: '40px', width: '70%' }}>
              Thank you for contacting us! Our team has received your request, and will get back to you as soon as possible.
              <br />
              <br />
              In the meantime, feel free to check out our LinkedIn page for updates.
            </p>
            <a href="https://www.linkedin.com/company/nova-carbon/" target="_blank" rel="noopener noreferrer">
              <img src={linkedIn} alt="Social Icon" className="social-icon" style={{ width: '25px', height: '25px' }} />
            </a>
          </div>
        )}

        {showCaptcha && !formSubmitted && (
          <div className={`captcha-container ${showCaptcha ? '' : 'hide'}`}>
            <ReCAPTCHA sitekey="6Ldzne8pAAAAALJgOOL3PyjC9Ky0hjUYlaDplY49" onChange={handleCaptchaChange} />
          </div>
        )}
      </div>
    </div>
  );
}

export const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  formContent: {
    width: '100%',
    height: 'calc(100% - 150px)',
    display: 'flex',
    paddingLeft: '4px',
    paddingRight: '4px',
    justifyContent: 'flex-end',
    alignSelf: 'center'
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    textAlign: 'left',
    paddingTop: '36px',
  },
  input: {
    borderRadius: '8px',
    backgroundColor: colors.white,
    width: '100%',
    marginTop: '12px',
    borderWidth: '1px',
    borderBlockColor: 'red',
    border: '1px solid rgba(238, 238, 238, 0.6)',
    fontFamily: 'Aeonik-Regular',
    fontSize: '13px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
    gap: '12px',
    position: 'absolute',
    bottom: '20px',
  },
  button: (bgColor, textColor, disabled) => ({
    backgroundColor: bgColor,
    color: textColor,
    opacity: disabled ? 0.2 : 1,
    zIndex: 200,
  }),
  textAlignCenter: {
    paddingBottom: '24px',
    textAlign: 'center',
    width: '80%',
  },
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    backgroundColor: colors.white,
    color: colors.black,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  modalFullScreen: {
    width: '100%',
    position: 'fixed',
    height: '100%',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
  },
  modalHalfScreen: {
    width: 'calc(50vw)',
    maxWidth: 'calc(50vw)',
    position: 'relative',
    height: '93%',
    overflow: 'hidden',
  },
  largeText: {
    fontSize: '48px',
    lineHeight: '56px',
    paddingTop: '24px',
  },
  smallText: {
    fontSize: '28px',
    lineHeight: '40px',
    paddingTop: '24px',
  },
  formContentInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  commentBox: {
    height: '170px',
    verticalAlign: 'flex-start',
  },
  horizontalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  menuItem: {
    fontFamily: 'Aeonik-Regular',
    fontSize: '13px'
  }
};

export default Form;
