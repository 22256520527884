import React, { useState, useEffect } from 'react';
import Introducer from './introducer';
import './ApplicationsGallery.css';
import './Global.css';
import { fetchApplications } from './cms/cmsApplications';
import colors from './colors';
import useIntersectionObserver from './useIntersectionObserver';
import './FadeIn.css'; 

const Applications = ({ phone }) => {
    const [applicationsData, setApplicationsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ref, hasIntersected] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      });

    useEffect(() => {
        const fetchApplicationsData = async () => {
            const data = await fetchApplications();
            setApplicationsData(data);
            setIsLoading(false);
        };
        fetchApplicationsData();
    }, []);

    if (isLoading || !applicationsData) {
        return <div style={{ height: '200px' }}>Loading...</div>;
    }

    return (
        <div ref={ref} className={`fade-in-section ${hasIntersected ? 'is-visible' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: phone ? '15px' : null, paddingRight: phone ? '15px' : null, paddingBottom: '40px', width: phone ? 'null' : '100%' }}>
            <Introducer  width={phone ? '80%' : '30%'}phone={phone} sectionTitle={applicationsData.header} title={applicationsData.title} body={applicationsData.body} />
            {phone ? <div style={{ flexDirection: 'column' }}>

                <div style={{paddingBottom:'12px'}}>
                    <img src={applicationsData.images[0].url} alt={applicationsData.images[0].title} style={{ width:'100%', borderRadius:'8px'}} />
                    {/* <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[0].title}</p> */}
                </div>

                <div style={{paddingBottom:'12px'}}>
                    <img src={applicationsData.images[1].url} alt={applicationsData.images[1].title} style={{ width:'100%', borderRadius:'8px'}} />
                   {/*  <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[1].title}</p> */}
                </div>
                <div style={{paddingBottom:'12px'}}>
                    <img src={applicationsData.images[2].url} alt={applicationsData.images[2].title} style={{ width:'100%', borderRadius:'8px'}} />
                    {/* <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[2].title}</p> */}
                </div>

                <div style={{paddingBottom:'12px'}}>
                    <img src={applicationsData.images[3].url} alt={applicationsData.images[3].title}style={{ width:'100%', borderRadius:'8px'}} />
                    {/* <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[3].title}</p> */}
                </div>

            </div>
                : <div className="applications-gallery">
                    <div className="column" style={{ paddingRight: '12px' }}>
                        <div>
                            <img src={applicationsData.images[0].url} alt={applicationsData.images[0].title} className="application-vertical" />
                           {/*  <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[0].title}</p> */}
                        </div>
                    </div>
                    <div className="column" style={{ paddingRight: '12px', paddingLeft: '12px' }}>
                    <div style={{paddingBottom:'20px'}}>
                            <img src={applicationsData.images[1].url} alt={applicationsData.images[1].title} className="application-horizontal" />
                            {/* <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[1].title}</p> */}
                        </div>
                        <div>
                            <img src={applicationsData.images[2].url} alt={applicationsData.images[2].title} className="application-horizontal" />
                            {/* <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[2].title}</p> */}
                        </div>
                    </div>
                    <div className="column" style={{ paddingLeft: '12px' }}>
                        <div>
                            <img src={applicationsData.images[3].url} alt={applicationsData.images[3].title} className="application-vertical" />
                            {/* <p className="small-text sub" style={{ color: colors.greyText }}>{applicationsData.images[3].title}</p> */}
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default Applications;
