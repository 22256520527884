import React, { useRef, useEffect, useState } from 'react';
import './Global.css';
import './testimonials.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { fetchTestimonials } from './cms/cmsTestimonials';
import buttonArrowSmall from './assets/button_arrow_small.png';

SwiperCore.use([Navigation, Autoplay]);

const Testimonials = ({ phone }) => {
  const swiperRef = useRef(null);
  const [testimonialsData, setTestimonialsData] = useState({ show: false, elements: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState('auto');

  useEffect(() => {
    const fetchTestimonialsData = async () => {
      try {
        const data = await fetchTestimonials();
        setTestimonialsData(data);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTestimonialsData();
  }, []);

  useEffect(() => {
    if (!isLoading && testimonialsData.elements.length > 0) {
      const heights = Array.from(document.querySelectorAll('.testimonial-slide')).map(
        (slide) => slide.scrollHeight
      );
      const maxHeight = Math.max(...heights);
      setMaxHeight(maxHeight);
    }
  }, [isLoading, testimonialsData]);

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  if (isLoading) {
    return <div style={{ height: '200px' }}>Loading...</div>;
  }

  if (testimonialsData.show) {

    return (
      <div className="testimonials-section">
        <div className="swiper-container" style={{ maxHeight: `${maxHeight}px` }}>
          <Swiper
            ref={swiperRef}
            slidesPerView={phone ? 'auto' : 1}
            loop
          >
            {testimonialsData.elements.map((testimonial, index) => (
              <SwiperSlide className={phone ? 'mobile' : ''} key={index}>
                <div className="testimonial-slide">
                  <h4 className="quote" style={{ fontSize: phone ? '20px' : '32px', lineHeight: phone ? '24px' : '40px', width: phone ? '100%' : '60%' }}>{testimonial.quote}</h4>
                  <img src={testimonial.avatar.url} alt={testimonial.name} className="avatar" />
                  <div className="testimonial-info">
                    <p className="large-text">{testimonial.name}</p>
                    <p className="main-text">{testimonial.function}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {testimonialsData.elements.length > 1 && (
          <div className="navigation-icons">
            <img src={buttonArrowSmall} alt="Previous" className="nav-icon" onClick={handlePrev} />
            <img src={buttonArrowSmall} style={{ transform: 'scaleX(-1)' }} alt="Next" className="nav-icon" onClick={handleNext} />
          </div>
        )}
      </div>
    )
  }
  ;
};

export default Testimonials;
