import React from 'react';
import PropTypes from 'prop-types';
import './Global.css';

function Tag({ content }) {
  return (
    <div className="tag">
      <span className="small-text">{content}</span>
    </div>
  );
}

Tag.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Tag;
