import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import NavBar from './navBar';
import NavBarPhone from './navBarPhone';
import './Global.css';
import colors from './colors';


const useDesktopMediaQuery = () =>
    useMediaQuery({ query: "(min-width: 1280px)" });

const useTabletAndBelowMediaQuery = () =>
    useMediaQuery({ query: "(max-width: 1279px)" });

const Desktop = ({ children }) => {
    const isDesktop = useDesktopMediaQuery();
    return isDesktop ? children : null;
};

const TabletAndBelow = ({ children }) => {
    const isTabletAndBelow = useTabletAndBelowMediaQuery();
    return isTabletAndBelow ? children : null;
};

const Error = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/');
    };

    return (
        <div className="fade-in" style={{ overflow: 'hidden', flex: 1 }}>
            <Desktop>
                <div style={{ overflow: 'hidden', flex: 1, height: 'calc(100vh)' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <NavBar hideMiddleSection={true} />
                        <h1 style={{ fontSize: '112px', color: colors.black, width: '100%', margin: 0, padding: 0, textAlign: 'center', paddingBottom: '20px' }}>404</h1>
                        <p style={{ paddingBottom: '20px', width: '25%', textAlign: 'center' }} className='main-text'>Page not found. The page you are looking for doesn't exist or has been moved.</p>
                        <button
                            onClick={handleGoBack}
                            className="rounded-button small-text"
                            style={{
                                backgroundColor: colors.green,
                                color: colors.black,
                                textAlign: 'center',
                            }}
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            </Desktop>
            <TabletAndBelow>
                <div style={{ overflow: 'hidden', flex: 1, height: 'calc(100vh)' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <NavBarPhone />
                        <h1 style={{ fontSize: '112px', color: colors.black, width: '100%', margin: 0, padding: 0, textAlign: 'center', paddingBottom: '20px' }}>404</h1>
                        <p style={{ paddingBottom: '20px', width: '80%', textAlign: 'center' }} className='main-text'>Page not found. The page you are looking for doesn't exist or has been moved.</p>
                        <button
                            onClick={handleGoBack}
                            className="rounded-button small-text"
                            style={{
                                backgroundColor: colors.green,
                                color: colors.black,
                                textAlign: 'center',
                            }}
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            </TabletAndBelow>
        </div>
    );
};

export default Error;
