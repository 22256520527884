const colors = {
    white: '#FFFFFF',
    black: '#0D0D0D',
    green: '#70DD03',
    greyText: 'rgba(24, 24, 24, 0.50)',
    greyBackground:'rgba(238, 238, 238, 0.6)',
    separator: '#181818'
};

export default colors;
