import React from 'react';
import './Global.css';
import colors from './colors';
import icon from './assets/icon.png';

const Footer = ({phone}) => {
  return (
    <div style={{ display: 'flex', background: colors.black, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: phone ? 'calc(100%)' : 'calc(100% + 96px)', paddingTop: '80px', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', marginBottom:'-2px' }}>
      <img src={icon} alt="Nova Carbon Logo" style={{ width: '80px', objectFit: 'cover' }} />
      <p style={{ color: '#EEEEEE', opacity: 0.6, textAlign: 'center', paddingBottom: '48px' }} className="small-text">© NOVA CARBON 2024<br />DESIGNED AND ENGINEERED IN FRANCE</p>
      <div style={{ height: '1px', width: '80%', backgroundColor: colors.separator, marginBottom: '48px' }} />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '80px' }}>
        <a href="/ColdPages" style={{ color: '#EEEEEE', opacity: 0.6, textAlign: 'center', textDecorationLine: 'none' }} className="small-text">Terms of use</a>
        <a href="/ColdPages" style={{ color: '#EEEEEE', opacity: 0.6, textAlign: 'center', paddingLeft: '32px', paddingRight: '32px', textDecorationLine: 'none' }} className="small-text">Privacy</a>
        <a href="/ColdPages" style={{ color: '#EEEEEE', opacity: 0.6, textAlign: 'center', textDecorationLine: 'none' }} className="small-text">Cookies</a>
      </div>
    </div>
  );
};

export default Footer;
