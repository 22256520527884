import React, { useRef, useEffect, useState } from 'react';
import './Global.css';
import './Partners.css';
import Introducer from './introducer';
import buttonArrowSmall from './assets/button_arrow_small.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import { fetchPartners } from './cms/cmsPartners';
import useIntersectionObserver from './useIntersectionObserver';
import './FadeIn.css';

SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);

const Partners = ({ phone }) => {
    const swiperRef = useRef(null);
    const [partnerData, setPartnerData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ref, hasIntersected] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1
    });

    useEffect(() => {
        const fetchPartnersData = async () => {
            const data = await fetchPartners();
            setPartnerData(data);
            setIsLoading(false);
        };
        fetchPartnersData();
    }, []);

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <div ref={ref} className={`fade-in-section ${hasIntersected ? 'is-visible' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <div className="partners-section">
                {partnerData && (
                    <div style={{ marginLeft: phone ? '30px' : '96px', width: '100%' }}>
                        <Introducer
                            phone={phone}
                            sectionTitle={partnerData.header}
                            title={partnerData.title}
                            body={partnerData.body}
                        />
                    </div>
                )}
                {isLoading ? (
                    <div style={{ height: '200px' }}></div>
                ) : (
                    <div className="swiper-wrapper">
                        <div className="swiper-container" >
                            <Swiper
                                ref={swiperRef}
                                slidesPerView={phone ? 'auto' : 4}
                                spaceBetween={phone ? null : 24}
                                loop
                            >
                                {partnerData.partners.map((partner) => (
                                    <SwiperSlide className={phone ? 'mobile' : ''} key={partner.id}>
                                        <div className="swiper-slide">
                                            <img src={partner.url} alt={`Partner ${partner.id}`} className="partner-icon" />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="navigation-icons">
                                <img src={buttonArrowSmall} alt="Previous" className="nav-icon" onClick={handlePrev} />
                                <img src={buttonArrowSmall} style={{ transform: 'scaleX(-1)' }} alt="Next" className="nav-icon" onClick={handleNext} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Partners;
