// cmsContact.js
import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchContactData = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          contact {
            body
            body1
            body2
            body3
            body4
            bottomtext1
            bottomtext2
            bottomtext3
            bottomtext4
            callToActionLeft
            header
            icon1 {
              url
            }
            icon2 {
              url
            }
            icon3 {
              url
            }
            icon4 {
              url
            }
            image {
              url
            }
            subtitleLeft
            title
            title1
            title2
            title3
            title4
            titleLeft
            link1
            link2
            link3
            link4
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.contact;
  } catch (error) {
    console.error('Error fetching contact data:', error);
    return null;
  }
};

export { fetchContactData };
