import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Home from './Home';
import smoothscroll from 'smoothscroll-polyfill';
import ColdPages from './coldPages';
import Error from './error';
import colors from './colors';

smoothscroll.polyfill();

const initializeGoogleAnalytics = () => {
  window.gtag('js', new Date());
  window.gtag('config', 'G-BWRZK1QGG9');
};

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (Cookies.get('acceptCookies') === 'true') {
      window.gtag('event', 'page_view', { page_path: location.pathname + location.search });
    }
  }, [location]);
};

const App = () => {
  const [isGtagInitialized, setIsGtagInitialized] = useState(false);

  useEffect(() => {
    if (Cookies.get('acceptCookies') === 'true' && !isGtagInitialized) {
      initializeGoogleAnalytics();
      setIsGtagInitialized(true);
    }
  }, [isGtagInitialized]);

  return (
    <Router>
      <PageTracker />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="acceptCookies"
        style={{ background: colors.white, color: colors.black, fontSize: "14px", fontFamily: 'Aeonik-Regular', borderRadius: '8px', boxShadow: '0 1px 8px rgba(0, 0, 0, 0.3)' }}
        buttonStyle={{ backgroundColor: colors.black, color: colors.white, fontSize: "14px", fontFamily: 'Aeonik-Regular', borderRadius: 100, paddingLeft: '13px', paddingRight: '13px', paddingBottom: '9px', paddingTop: '8px', marginRight: '20px' }}
        expires={150}
        onAccept={() => {
          initializeGoogleAnalytics();
          setIsGtagInitialized(true);
        }}
      >
        The Nova Carbon® website uses cookies to enhance the user experience.{" "}
        <span style={{ color: colors.black, fontSize: "14px", fontFamily: 'Aeonik-Regular' }}>By continuing to browse the site, you are agreeing to our use of cookies.</span>
      </CookieConsent>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coldPages" element={<ColdPages />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};

const PageTracker = () => {
  usePageTracking();
  return null;
};

export default App;
