
import React from 'react';
import PropTypes from 'prop-types';

function Introducer({ title, body, sectionTitle, phone, width }) {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <p style={{ paddingBottom: '2px', fontSize: phone ? '14px' : '16px' }} className="main-text">{sectionTitle}</p>
            <h3 style={{  paddingBottom: '36px', fontSize: phone ? '32px' : '48px', lineHeight: phone ? '40px' : '56px', width: width ? width :  phone ? '90%' : '60%' }}>{title}</h3>
            <p style={{ width: phone ? '90%' : '45%', paddingBottom: phone ? '60px' : '80px' }} className="main-text">{body}</p>
        </div>
    );
}

Introducer.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    phone: PropTypes.bool,
};

export default Introducer;
