import React from 'react';
import linkedIn from './assets/linkedIn.png';
import './TeamSection.css';
import colors from './colors';

const TeamMember = ({ image, name, position, description, link }) => {
  return (
    <a href={link} target='blank' className="team-member" style={{ textDecoration: 'none', color: 'inherit' }}>
      <img src={image} alt={name} className="member-image" />
      <div className="large-text">{name}</div>
      <div className="main-text" style={{ paddingBottom: '12px', color: colors.black }}>{position}</div>
      <div className="main-text" style={{ paddingBottom: '16px', textAlign: 'center', width: '85%' }}>{description}</div>
      <div className="social-media">
        <img src={linkedIn} alt="Social Icon" className="social-icon" />
      </div>
    </a>
  );
};

export default TeamMember;
