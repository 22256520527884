import React, { useRef, useEffect, useState } from 'react';
import './Global.css';
import './Partners.css';
import buttonArrowSmall from './assets/button_arrow_small.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import colors from './colors';
import { fetchCollaboratorsData } from './cms/cmsCollaborators';

SwiperCore.use([Navigation, Autoplay]);

const Collaborators = ({ phone }) => {
    const swiperRef = useRef(null);
    const [collaboratorData, setCollaboratorData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchCollaboratorsData();
            setCollaboratorData(data);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    if (isLoading) {
        return <div style={{ height: '200px' }}></div>;
    }

    if (collaboratorData.show) {
        return (
            <div style={{ paddingTop: phone ? '15px' : 0 }} className="partners-section">
                <div className="swiper-container">
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={phone ? 'auto' : 4}
                        spaceBetween={phone ? null : 24}
                        loop
                    >
                        {collaboratorData.member.map((collaborator, index) => (
                            <SwiperSlide className={phone ? 'mobile' : ''} key={index}>
                                <div style={{ flexDirection: 'column' }} className="swiper-slide">
                                    <img src={collaborator.image.url} alt={collaborator.name} className="member-image" />
                                    <div style={{ color: colors.black }} className="main-text">{collaborator.name}</div>
                                    <div style={{ color: colors.greyText }} className="small-text">{collaborator.function}</div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="navigation-icons">
                        <img src={buttonArrowSmall} alt="Previous" className="nav-icon" onClick={handlePrev} />
                        <img src={buttonArrowSmall} style={{ transform: 'scaleX(-1)' }} alt="Next" className="nav-icon" onClick={handleNext} />
                    </div>
                </div>
            </div>
        )
    }
};

export default Collaborators;
