// Team.js
import React, { useState, useEffect } from 'react';
import './Global.css';
import './TeamSection.css';
import Introducer from './introducer';
import TeamMember from './teamMember';
import { fetchFoundersData } from './cms/cmsFounders';
import useIntersectionObserver from './useIntersectionObserver';
import './FadeIn.css'; 

const Team = ({ phone }) => {
  const [foundersData, setFoundersData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ref, hasIntersected] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchFoundersData();
      setFoundersData(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading || !foundersData) {
    return <div style={{ height: '200px' }}>Loading...</div>;
  }

  return ( 
    <div  ref={ref} className={`fade-in-section ${hasIntersected ? 'is-visible' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: phone ? '15px' : null, paddingRight: phone ? '15px' : null, paddingTop: '80px', paddingBottom:'60px' }}>
      <Introducer phone={phone} sectionTitle={foundersData.header} title={foundersData.title} body={foundersData.body} />
      {phone ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>

        <div style={{ paddingBottom: '15px' }}>
          <TeamMember
            image={foundersData.founderpicture1.url}
            name={foundersData.foundername1}
            position={foundersData.function1}
            description={foundersData.description1}
            link={foundersData.link1}
          />
        </div>
        <TeamMember
          image={foundersData.founderpicture2.url}
          name={foundersData.foundername2}
          position={foundersData.function2}
          description={foundersData.description2}
          link={foundersData.link2}
        />

      </div>
        : <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <div className="team-section">
            <div className="team-row">
              <TeamMember
                image={foundersData.founderpicture1.url}
                name={foundersData.foundername1}
                position={foundersData.function1}
                description={foundersData.description1}
                link={foundersData.link1}
              />
              <TeamMember
                image={foundersData.founderpicture2.url}
                name={foundersData.foundername2}
                position={foundersData.function2}
                description={foundersData.description2}
                link={foundersData.link2}
              />
            </div>
          </div>
        </div>}

    </div>
  );
};

export default Team;
