// cmsApplications.js
import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchApplications = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
            applicationsGallery {
                id
                title
                header
                body
                images {
                    url
                    title 
                }
            }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.applicationsGallery;
  } catch (error) {
    console.error('Error fetching applications:', error);
    return null;
  }
};

export { fetchApplications };
