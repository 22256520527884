import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchHeroData = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
          {
            heroSection {
              tagline
              image {
                url
              }
              callToAction
            }
          }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    const heroSection = response.data.data.heroSection;
    return {
      image: heroSection.image.url,
      tagline: heroSection.tagline,
      callToAction: heroSection.callToAction
    };
  } catch (error) {
    console.error('Error fetching hero data:', error);
    return {
      image: '',
      tagline: '',
      callToAction: ''
    };
  }
};

export { fetchHeroData };
