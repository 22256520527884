import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';
import './Global.css';
import { useModal } from './modalContext';

function Button({ color, textColor, content, variant }) {
  const { setShowModal, setVariant } = useModal();

  const handleClick = () => {
    setShowModal(true);
    setVariant(variant);
  };

  return (
    <button
      className="rounded-button small-text"
      style={{ backgroundColor: color, color: textColor, textAlign: 'center' }}
      onClick={handleClick}
    >
      {content}
    </button>
  );
}

Button.propTypes = {
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default Button;
