import React from 'react';
import './Global.css';
import euLogo from './assets/aquitaine.png';
import colors from './colors';

const Aquitaine = ({ phone }) => {
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center', paddingBottom:phone ? '80px': null }}>
      <p className="small-text" style={{ marginBottom: '30px', textAlign: 'center', width: phone ? '80%' : '25%', color: colors.greyText }}>
        The Nouvelle Aquitaine Region and the European Union are supporting Nova Carbon as part of the 2021-2027 ERDF/ESF Operational Program.
      </p>
      <img src={euLogo} alt="EU Logo" style={{ width: '120px', objectFit: 'cover', borderRadius: '8px' }} />
    </div>
  );
};

export default Aquitaine;
