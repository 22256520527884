import axios from 'axios';

const API_TOKEN = 'df79b5717def1984265d8c758e679c';
const API_URL = 'https://graphql.datocms.com/';

const fetchCollaboratorsData = async () => {
  try {
    const response = await axios.post(
      API_URL,
      {
        query: `
        {
          collaborator {
            show
            member {
              function
              name
              image {
                url
              }
            }
          }
        }
        `
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_TOKEN}`
        }
      }
    );
    return response.data.data.collaborator;
  } catch (error) {
    console.error('Error fetching collaborators data:', error);
    return { show: false, member: [] };
  }
};

export { fetchCollaboratorsData };
