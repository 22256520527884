import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import './Global.css';
import './FadeIn.css';
import NavBar from './navBar';
import NavBarPhone from './navBarPhone';
import Hero from './hero';
import Facts from './facts';
import Applications from './applications';
import Team from './team';
import Partners from './partners';
import Contact from './contact';
import Footer from './footer';
import ImageGallery from './imageGallery';
import Collaborators from './collaborators';
import ContextPhrase from './contextPhrase';
import Aquitaine from './aquitaine';
import Testimonials from './testimonials';
import Loader from './Loader';
import Numbers from './numbers';
import Form from './form';
import { useModal, ModalProvider } from './modalContext';

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: '(min-width: 1280px)' });

const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: '(max-width: 1279px)' });

const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();
  return isDesktop ? children : null;
};

const TabletAndBelow = ({ children }) => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery();
  return isTabletAndBelow ? children : null;
};

const HomeContent = () => {
  const { showModal, setShowModal, variant, resetModalStates } = useModal();
  const isTabletAndBelow = useTabletAndBelowMediaQuery();
  const phone = isTabletAndBelow;

  const closeModal = () => {
    setShowModal(false);
    resetModalStates();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="fade-in-all" style={{ overflow: 'hidden', flex: 1 }}>
      <Desktop>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '48px',
            paddingRight: '48px',
          }}
        >
          {showModal ? null : <NavBar />}
          <Hero />
          <ContextPhrase />
          <div style={{ width: '100%' }} id="technology">
            <Facts />
          </div>
          <ImageGallery />
          <Numbers />
          <div style={{ width: '100%' }} id="application">
            <Applications />
          </div>
          <div style={{ width: '100%' }} id="team">
            <Team />
          </div>
          <Collaborators />
          <div style={{ width: 'calc(100% + 96px)' }} id="partners">
            <Partners />
          </div>
          <Aquitaine />
          <Testimonials />
          <Contact />
          <Footer />
        </div>
      </Desktop>
      <TabletAndBelow>
        {showModal ? null :
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <NavBarPhone />
            <Hero phone />
            <ContextPhrase phone />
            <Facts phone />
            <ImageGallery phone />
            <Numbers phone />
            <Applications phone />
            <Team phone />
            <Collaborators phone />
            <Partners phone />
            <Aquitaine phone />
            <Testimonials phone />
            <Contact phone />
            <Footer phone />
          </div>
        }
      </TabletAndBelow>

      {showModal && <Form phone={phone} onClose={closeModal} short={variant === 'short'} />}
    </div>
  );
};

function Home() {
  return (
    <ModalProvider>
      <HomeContent />
      <Loader />
    </ModalProvider>
  );
}

export default Home;
