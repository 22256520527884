import React, { useState, useEffect } from 'react';
import './Global.css';
import Fact from './fact';
import { fetchFactsData } from './cms/cmsFacts';
import useIntersectionObserver from './useIntersectionObserver';
import './FadeIn.css'; 

const Numbers = ({ phone }) => {
    const [factsData, setFactsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ref, hasIntersected] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      });

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchFactsData();
            setFactsData(data);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    if (isLoading || !factsData) {
        return <div style={{ height: '200px' }}>Loading...</div>;
    }

    const facts = [
        { number: factsData.number1, body: factsData.description1 },
        { number: factsData.number2, body: factsData.description2 },
        { number: factsData.number3, body: factsData.description3 }
    ];

    return (
        <div ref={ref} className={`fade-in-section ${hasIntersected ? 'is-visible' : ''}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '15px', paddingBottom: phone ?  '120px' : '160px', width: phone ? '100%' : '105%' }}>
            <div style={{ display: 'flex', flexDirection: phone ? 'column' : 'row', justifyContent: phone ? 'center' : 'space-around', alignItems: phone ? 'center' : null, width: phone ? '100%' : '100%' }}>
                {facts.map((fact, index) => (
                    <Fact phone={phone} key={index} number={fact.number} body={fact.body} index={index} />
                ))}
            </div>
        </div>
    );
};

export default Numbers;
