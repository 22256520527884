// ColdPages.js
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { fetchColdPages } from './cms/cmsColdPages';
import NavBar from './navBar';
import './Global.css';
import Footer from './footer';
import colors from './colors';
import NavBarPhone from './navBarPhone';
import arrow from './assets/arrow.png';

const useDesktopMediaQuery = () =>
    useMediaQuery({ query: "(min-width: 1280px)" });

const useTabletAndBelowMediaQuery = () =>
    useMediaQuery({ query: "(max-width: 1279px)" });

const Desktop = ({ children }) => {
    const isDesktop = useDesktopMediaQuery();
    return isDesktop ? children : null;
};

const TabletAndBelow = ({ children }) => {
    const isTabletAndBelow = useTabletAndBelowMediaQuery();
    return isTabletAndBelow ? children : null;
};

const ColdPages = () => {
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [coldPages, setColdPages] = useState({});
    const [selectedButton, setSelectedButton] = useState('termsofuse'); // Default selected button

    useEffect(() => {
        const getContent = async () => {
            const data = await fetchColdPages();
            setColdPages(data);
            setIsLoading(false);
            setContent(data.termsofuse);
        };
        getContent();
    }, []);

    const handleButtonClick = (type) => {
        setSelectedButton(type);
        setContent(coldPages[type]);
    };

    if (isLoading) {
        return <div></div>;
    }

    return (
        <div className="fade-in" style={{ overflow: 'hidden', flex: 1 }}>
            <Desktop>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '48px', paddingRight: '48px', paddingTop: '120px' }}>
                    <a style={{position:'absolute', top:'43px', left:'64px'}} href="/"> <img style={{width:'30px', height:'30px', objectFit:'cover'}} src={arrow} alt="arrow" /></a>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: 'rgba(238, 238, 238, 0.5)', borderRadius: '8px', paddingTop: '80px', paddingBottom: '80px', marginBottom: '80px' }}>
                        <h3 style={{ textAlign: 'center', paddingBottom: '24px' }}>We care about your privacy</h3>
                        <p className='large-text' style={{ textAlign: 'center', width: '40%', color: colors.greyText, paddingBottom: '24px' }}>We respect your privacy regarding any information we may collect from you across our website.</p>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <button
                                className="rounded-button small-text"
                                style={{
                                    backgroundColor: selectedButton === 'termsofuse' ? colors.green : colors.greyBackground,
                                    color: colors.black,
                                    textAlign: 'center'
                                }}
                                onClick={() => handleButtonClick('termsofuse')}
                            >
                                Terms of Use
                            </button>
                            <button
                                className="rounded-button small-text"
                                style={{
                                    backgroundColor: selectedButton === 'privacy' ? colors.green : colors.greyBackground,
                                    color: colors.black,
                                    textAlign: 'center',
                                    margin: '0 10px'
                                }}
                                onClick={() => handleButtonClick('privacy')}
                            >
                                Privacy
                            </button>
                            <button
                                className="rounded-button small-text"
                                style={{
                                    backgroundColor: selectedButton === 'cookies' ? colors.green : colors.greyBackground,
                                    color: colors.black,
                                    textAlign: 'center'
                                }}
                                onClick={() => handleButtonClick('cookies')}
                            >
                                Cookies
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '80px' }}>
                        <p style={{ width: '60%' }} className='main-text'>{content}</p>
                    </div>
                    <Footer />
                </div>
            </Desktop>
            <TabletAndBelow>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: '15px', paddingRight: '15px', paddingTop: '120px' }}>
                    <NavBarPhone />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: 'rgba(238, 238, 238, 0.5)', borderRadius: '8px', paddingTop: '50px', paddingBottom: '50px', marginBottom: '80px' }}>
                        <h3 style={{ textAlign: 'center', paddingBottom: '24px', fontSize:  '32px', lineHeight: '40px' }}>We care about your privacy</h3>
                        <p className='large-text' style={{ textAlign: 'center', width: '40%', color: colors.greyText, paddingBottom: '24px',  fontSize:  '16px', width:'90%'  }}>We respect your privacy regarding any information we may collect from you across our website.</p>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <button
                                className="rounded-button small-text"
                                style={{
                                    backgroundColor: selectedButton === 'termsofuse' ? colors.green : colors.greyBackground,
                                    color: colors.black,
                                    textAlign: 'center'
                                }}
                                onClick={() => handleButtonClick('termsofuse')}
                            >
                                Terms of Use
                            </button>
                            <button
                                className="rounded-button small-text"
                                style={{
                                    backgroundColor: selectedButton === 'privacy' ? colors.green : colors.greyBackground,
                                    color: colors.black,
                                    textAlign: 'center',
                                    margin: '0 10px'
                                }}
                                onClick={() => handleButtonClick('privacy')}
                            >
                                Privacy
                            </button>
                            <button
                                className="rounded-button small-text"
                                style={{
                                    backgroundColor: selectedButton === 'cookies' ? colors.green : colors.greyBackground,
                                    color: colors.black,
                                    textAlign: 'center'
                                }}
                                onClick={() => handleButtonClick('cookies')}
                            >
                                Cookies
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '80px' }}>
                        <p style={{ width: '80%' }} className='main-text'>{content}</p>
                    </div>
                    <Footer />
                </div>
            </TabletAndBelow>
        </div>
    );
};

export default ColdPages;
