import React from 'react';
import PropTypes from 'prop-types';
import './Fact.css';
import './Global.css';
import colors from './colors';
import Tag from './tag';

function Fact({ number, body, phone, index }) {
  return (
    <div style={{ position:'relative', display: 'flex', width: phone ? 'calc(100% - 30px)' : 'calc(100% / 3 - 15px)', alignItems: 'center', backgroundColor: colors.greyBackground, borderRadius: '8px', paddingTop: '40px', paddingBottom: '40px', marginBottom: phone ? '15px' : null }} className="fact">
      <h1 style={{ margin: 0, textAlign: 'center', width: '100%' }}>{number}</h1>
      <p style={{ paddingTop: '12px', color: colors.greyText, width: '80%', textAlign: phone ? 'center' : 'center' }} className="main-text">{body}</p>
    </div>
  );
}

Fact.propTypes = {
  number: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  phone: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default Fact;
