import React, { useState, useRef, useEffect } from 'react';
import './ImageGallery.css';
import './Global.css';
import Tag from './tag';
import { fetchGalleryItems } from './cms/cmsGallery';
import colors from './colors';

const ImageGallery = ({ phone }) => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const galleryItems = await fetchGalleryItems();
      setImages(galleryItems);
    };
    fetchData();
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold: 0.7 }
    );
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleClick = (index) => {
    setCurrentIndex(index);
    if (initialLoad) {
      setInitialLoad(false);
    }
  };
  
  return (
    <div
      className={`gallery-container ${isInView ? 'in-view' : ''}`}
      ref={containerRef}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div className={phone ? 'info-container-phone' : 'info-container'}>
        {images.map((image, index) => (
          <div
            key={image.id}
            className={`info-item${phone ? '-phone':''} ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleClick(index)}
            style={{ alignSelf: phone ? 'center' : null }}
          >
            <div style={{ opacity: index === currentIndex ? 1 : 0.5, transition: 'opacity 1s ease' }}>
              <Tag content={image.tag} />
            </div>
            <h5
              style={{ width: '100%', paddingTop: '8px', paddingBottom: '8px' }}
              className="title"
            >
              {image.title}
            </h5>
            <div
              style={{ color: colors.greyText, width: '100%' }}
              className="small-text"
            >
              {image.body}
            </div>
          </div>
        ))}
      </div>
      <div className="image-container">
        <div className={phone ? 'gallery-image-phone' : 'gallery-image'} style={{ backgroundColor: 'black' }} />
        {images.map((image, index) => (
          <img
            key={image.id}
            src={image.image.url}
            alt=""
            style={{
              opacity: index === currentIndex ? 1 : 0,
              position: 'absolute',
              height: '100%',
              objectFit: 'cover'
            }}
            className={phone ? 'gallery-image-phone' : 'gallery-image'}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
